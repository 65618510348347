import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Solutions from "../components/solutions"
import { useStaticQuery, graphql } from "gatsby"

const IndexPage = props => {
  const data = useStaticQuery(graphql`
  {
    file(relativePath: {eq: "logo.png"}) {
      publicURL
    }
  }
  `)
  return (
    <Layout>
      <SEO image={data.file.publicURL} title="Home" location={props.location} />
      <Hero />
      <Solutions />
    </Layout>
  )
}
export default IndexPage
import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import Img from "gatsby-image"
import convertHex from "../utils/convertHex"
import getRatio from "../utils/getRatio"
import IFrame from "./iframe"
import { getColorClass } from '../utils/getColorClass'

const Solutions = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        sort: { order: ASC, fields: frontmatter___order }
        filter: {frontmatter: {tag: {eq: "Solution"}}}
      ) {
        nodes {
          id
          frontmatter {
            title
            path
            colour
            icon {
              publicURL
            }
            tileImgHome {
              publicURL
            }
            frontImg {
              childImageSharp {
                fluid(maxWidth: 700) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            mainVid
            vidWidth
            vidHeight
            imgWidth
            tallVideo
            summary
          }
          excerpt
        }
      }
    }
  `)
  return (
    <section className="solutions">
      <h2 className="title -main">Solutions</h2>
      {data.allMarkdownRemark.nodes.map((post, index) => (
        <div key={"solution_"+post.id+index} className="solution">
          <div className="container -sol">
            <div
              className={`portrait ${index % 2 === 0 ? "box -even" : "box"}`}
              key={post.id}
            >
              <div className={`image ${post.frontmatter.tallVideo ? "tall-video":""} ${index === 0 ? "push-up":""}`} css={{'@media(min-width: 768px)':{maxWidth: post.frontmatter.imgWidth+"px !important"}}}>
                <Img className="p-image" fluid={post.frontmatter.frontImg.childImageSharp.fluid} />
                <div className="p-video">
                  <IFrame ratio={getRatio(post)} videoSrcURL={`https://player.vimeo.com/video/${post.frontmatter.mainVid}?background=1`} videoTitle="High Precision Visual Tracking for Resource Constrained Devices" />
                </div>
              </div>
              <div className="content">
                <div>
                  <img className="icon" src={post.frontmatter.icon.publicURL} alt="Icon" />
                  <h3 className="title -sub"><Link to={post.frontmatter.path} css={{ ':hover': {color: post.frontmatter.colour +" !important"}}}>{post.frontmatter.title}</Link></h3>
                </div>
                <p className="text">{post.frontmatter.summary}</p>
                <Link className={`button ${getColorClass(post.frontmatter.colour)}`} to={post.frontmatter.path} css={{background: post.frontmatter.colour +" !important", color: "white"}}>Read More</Link>
              </div>
            </div>
          </div>
          <div
            className="tile"
            style={{
              backgroundImage:
                "url(" + post.frontmatter.tileImgHome.publicURL + ")",
              backgroundRepeat: "repeat-x",
            }}
          >
            <div
              className="fade"
              style={{
                background: `linear-gradient(${
                  index % 2 === 0 ? "90deg" : "270deg"
                }, rgba(0,0,0,0.2) 0%, ${convertHex(
                  post.frontmatter.colour,
                  50
                )} 100%)`,
              }}
            ></div>
          </div>
        </div>
      ))}
    </section>
  )
}
export default Solutions

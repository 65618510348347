import React from "react"
import Form from "./form"
import IFrame from "./iframe"
// import Video from "./video"


const Hero = () => {
  return (
    <section style={{background : "black"}}>
      <div className="container hero">
        <h1 className="title">Track with confidence</h1>
        <p className="text">
          Visual Tracking Software for Resource Constrained Devices
        </p>
        <Form />
        <IFrame videoSrcURL="https://player.vimeo.com/video/397094785?background=1" videoTitle="High Precision Visual Tracking for Resource Constrained Devices" />
        {/* <Video /> */}
        {/* https://player.vimeo.com/video/397095672/5202d86821 */}
      </div>
    </section>
  )
}
export default Hero
